import {
  Locales,
  alertMessage,
  commons,
  daysOfWeek,
  kitchenManager,
  reasonOnOff,
  storeManager,
  storeStatus,
  scheduled,
  countries,
  brandManager,
  modulesLocale,
  StatusLocale,
} from "./types";

export const ptLocale: Locales = {
  [commons.SAVE]: "Guardar",
  [commons.LOADING]: "Cargando",
  [commons.ERROR_TITLE]: "A página não existe ou você não tem acesso...",
  [commons.ERROR_SUBTITLE]: "Entre em contato com o administrador.",
  [commons.REQUIRED_FIELD]: "Valor obrigatório",
  [commons.QUANTITY_ERROR]: "A quantidade deve ser maior que 0",
  [commons.ADD]: "Adicionar",
  [commons.MODIFY]: "Modificar",
  [commons.VIEW_DETAIL]: "Ver detalhes",
  [commons.CANCEL]: "Cancelar",
  [commons.CONTINUE]: "Continuar",
  [commons.SEARCH]: "Pesquisar",
  [commons.COUNTRY]: "País",
  [commons.CITY]: "Cidade",
  [commons.LOCATION]: "Localização",
  [commons.FILTERS]: "Filtros",
  [commons.PLATFORM]: "Plataforma",
  [commons.TABLE_EMPTY]: "Não há registros para exibir",
  [commons.STATUS]: "Status",
  [commons.BRAND]: "Marca",
  [commons.ACTION]: "Ação",
  [commons.UPDATE]: "Atualizar",
  [commons.SET_UP]: "Configurar",
  [commons.STORE]: "Loja",
  [commons.SCHEDULE]: "Agendar",
  [commons.TIMETABLE]: "Calendário",
  [commons.TURN_ON]: "Ligar",
  [commons.TURN_OFF]: "Desligar",
  [commons.ON]: "Ativado",
  [commons.OFF]: "Desligado",
  [commons.CREATE]: "Criar",
  [commons.THEORETICAL]: "Teórico",
  [commons.REASON]: "Motivo",
  [commons.START]: "Iniciar",
  [commons.END]: "Fim",
  [commons.ID]: "Id",
  [commons.NAME]: "Nome",
  [commons.KITCHEN]: "Cozinha",
  [commons.ALL]: "Todos",
  [commons.RETURN]: "Volte",
  [commons.ALL_CITIES]: "Todos",
  [commons.ALL_KITCHENS]: "Todos",
  [commons.DATE]: "Data",
  [commons.DELETE]: "Eliminar",
  [commons.ACCEPT]: "Aceitar",
  [commons.TIME_ZONE]: "Fuso horário",
  [commons.COUNTRIES]: "Países",
  [commons.STATION_TYPE]: "Tipo estação",
  [commons.TYPE]: "Tipo",
  [commons.IMAGE]: "Imagem",
  [commons.UPLOAD_IMAGE]: "Carregar imagem",
  [commons.CLIC_TO_UPLOAD_IMAGE]: "Clique para fazer upload da imagem",
  [commons.ADDRESS]: "Endereço",
  [commons.POLYGONS]: "Polígonos",
  [commons.ALERT]: "Alerta",
  [commons.WARNING]: "Aviso",
  [commons.EXPECTED]: "Esperado",
  [commons.LONGITUDE]: "Comprimento",
  [commons.LATITUDE]: "Latitude",
  [commons.ACTIVE]: "Ativo",
  [commons.INACTIVE]: "Inativo",
  [commons.LOW]: "Baixo",
  [commons.MEDIUM]: "Médio",
  [commons.HIGH]: "Alto",
  [commons.COVERAGE]: "Cobertura",
  [commons.YES]: "Sim",
  [commons.NO]: "Não",
  [commons.ENTER]: "Entrar",

  [storeManager.TITLE]: "Gerente de loja",
  [storeManager.MARKETPLACE]: "Marketplace",
  [storeManager.IS_PRIORITY]: "Turbo",
  [storeManager.IS_PARENT]: "Loja pai",
  [storeManager.STORES]: "Lojas",
  [storeManager.SCHEDULED]: "Agendado",

  [brandManager.TITLE]: "Gerente de marca",

  [kitchenManager.TITLE]: "Gerente de cozinha",
  [kitchenManager.LINK_STATION]: "Estações associadas",
  [kitchenManager.LINK_STATION_SUCCESS]: "Estações associadas corretamente",
  [kitchenManager.SETUP_TITLE]: "Configurar cozinhas",
  [kitchenManager.PRODUCTION_CENTER]: "Centro de Produção",
  [kitchenManager.KITCHEN]: "Cozinha",
  [kitchenManager.BOTH]: "Ambos",
  [kitchenManager.RAPPIALLIESUSER]: "Aliados do usuário Rappi",
  [kitchenManager.RAPPIALLIESPASSWORD]: "Senha dos Aliados Rappi",

  [storeStatus.Upcoming]: "Próximos",
  [storeStatus.Launched]: "Aberto",
  [storeStatus.Paused]: "Temporariamente fechado",
  [storeStatus.Terminated]: "Permanentemente fechado",
  [storeStatus.OPEN]: "Aberto",
  [storeStatus.NOT_LAUNCHED]: "Não lançado",
  [storeStatus.PERM_CLOSED]: "Permanentemente fechado",
  [storeStatus.TEMP_CLOSED]: "Temporariamente fechado",
  [storeStatus.INTERNAL]: "Interno",
  [storeStatus.REMOTE]: "Remoto",

  [alertMessage.ROWS_SELECTED]: "Você selecionou ({{rows}}) registros.",
  [alertMessage.SUCCESSFUL_PROCESS]: "Processo concluído com sucesso.",
  [alertMessage.FAILED_PROCESS]: "Ocorreu um erro de processo.",

  [reasonOnOff.OPERATING_CORRECTLY]: "Operando corretamente",
  [reasonOnOff.STAFF_SHORTAGE]: "Falta de pessoal",
  [reasonOnOff.KITCHEN_OPENING_ISSUES]: "Problemas na abertura da cozinha",
  [reasonOnOff.GAS_FAILURES]: "Falhas de gás",
  [reasonOnOff.INTERNET_FAILURES]: "Falhas na Internet",
  [reasonOnOff.POWER_FAILURES]: "Falhas de energia",
  [reasonOnOff.WATER_FAILURES]: "Falhas de água",
  [reasonOnOff.STOCKOUTS]: "Estoques",
  [reasonOnOff.SALES_OVERFLOW]: "Vendas excessivas",
  [reasonOnOff.SPECIAL_EVENTS]: "Eventos especiais",
  [reasonOnOff.SCHEDULED_MAINTENANCE]: "Manutenção agendada",
  [reasonOnOff.RELOCATION]: "Mover ou transferir",
  [reasonOnOff.STAFF_TRAINING]: "Treinamento de pessoal",
  [reasonOnOff.OUT_OF_SERVICE_BRAND]: "Marca fora de serviço",
  [reasonOnOff.INTEGRATION_LACK]: "Falta de integração",
  [reasonOnOff.WITHOUT_REASON]: "Sem motivo",
  [reasonOnOff.BY_SYSTEM]: "Por sistema",
  [reasonOnOff.CLOSED_BY_PLATFORM]: "Fechado pela plataforma",

  [daysOfWeek.MONDAY]: "Segunda-feira",
  [daysOfWeek.TUESDAY]: "Terça-feira",
  [daysOfWeek.WEDNESDAY]: "Quarta-feira",
  [daysOfWeek.THURSDAY]: "Quinta-feira",
  [daysOfWeek.FRIDAY]: "Sexta-feira",
  [daysOfWeek.SATURDAY]: "Sábado",
  [daysOfWeek.SUNDAY]: "Domingo",

  [scheduled.CHAGE_STATUS_MESSAGE]: "Deseja arquivar esta programação?",
  [scheduled.ARCHIVE]: "Aarquivar",

  [countries.COL]: "Colômbia",
  [countries.PER]: "Peru",
  [countries.BRA]: "Brasil",
  [countries.MEX]: "México",
  [countries.ECU]: "Equador",

  [modulesLocale.SELECT_MODULE_TITLE]: "Selecionar módulo",
  [modulesLocale.SELECT_MODULE_SUBTITLE]: "Selecionar ferramenta",
  [modulesLocale.ADMIN_TITLE]: "Administrador",
  [modulesLocale.ADMIN_SUBTITLE]:
    "Selecione a ferramenta que deseja usar para gerenciar suas lojas.",

  [StatusLocale.PENDING]: "Pendente",
  [StatusLocale.DONE]: "Concluído",
  [StatusLocale.ARCHIVED]: "Arquivado",
};
