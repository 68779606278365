import { KOSRowData } from "@foodology-co/alejandria";
import { ScheduleType } from "app/components/Store/SetUp/Schedule/utils";

export interface StoreQuery {
  params: Param;
  select: string[];
  cursor: Cursor;
}

export enum StoreAvailabilityOperation {
  Upcoming = "Upcoming",
  Launched = "Launched",
  Terminated = "Terminated",
}

export type StoreAvailabilityOperationType = `${StoreAvailabilityOperation}`;

export enum StoreStatus {
  Upcoming = "Upcoming",
  Launched = "Launched",
  Paused = "Paused",
  Terminated = "Terminated",
}

export type StoreStatusType = `${StoreStatus}`;

export enum StoreStatus2 {
  OPEN = "OPEN",
  TEMP_CLOSED = "TEMP_CLOSED",
  NOT_LAUNCHED = "NOT_LAUNCHED",
  PERM_CLOSED = "PERM_CLOSED",
}

export type StoreStatusType2 = `${StoreStatus2}`;

export interface LocationParam {
  kitchen?: string;
  city?: string;
  country?: string;
}

export interface Param {
  ownerId?: string;
  include: StoreStatusType[];
  location?: LocationParam;
  companyId: string;
}

export interface Cursor {
  after?: string;
  limit: number;
}

export interface PaginatedStores {
  list: Store[];
  next: StoreQuery;
}

export interface StoreOnline {
  planned: boolean;
  remote: boolean;
}

export interface PedidosYaConfig {
  chainId: string;
  remoteId: string;
}

export interface StoreAvailability {
  operation: StoreAvailabilityOperationType;
  paused?: boolean;
}

export interface StoreSchedule {
  monday: string[];
  tuesday: string[];
  wednesday: string[];
  thursday: string[];
  friday: string[];
  saturday: string[];
  sunday: string[];
}

export interface Store {
  id: string;
  ownerId: string;
  externalId: string; // For compat purposes, id == externalId
  brandId: string;
  kitchenId: string;
  platform: string;
  country: string;
  city: string;
  availability: StoreAvailability;
  online: StoreOnline;
  data: any;
  tags: any;
}

export const reasonsOnOff: KOSRowData = {
  TURN_ON: ["OPERATING_CORRECTLY", "WITHOUT_REASON"],
  TURN_OFF: [
    "STAFF_SHORTAGE",
    "KITCHEN_OPENING_ISSUES",
    "GAS_FAILURES",
    "INTERNET_FAILURES",
    "POWER_FAILURES",
    "WATER_FAILURES",
    "STOCKOUTS",
    "SALES_OVERFLOW",
    "SPECIAL_EVENTS",
    "SCHEDULED_MAINTENANCE",
    "RELOCATION",
    "STAFF_TRAINING",
    "OUT_OF_SERVICE_BRAND",
    "INTEGRATION_LACK",
    "WITHOUT_REASON",
  ],
};

export interface AvailabilityPlannedPayload {
  ids: string[];
  availability?: StoreAvailability;
  reason?: string;
}

export interface StoreAvailabilityRemote {
  online: boolean;
}

export interface AvailabilityRemotePayload {
  ids: string[];
  availability?: StoreAvailabilityRemote;
}

export interface SetLegacySchedulePayload {
  ids: string[];
  type: ScheduleType;
  data: StoreSchedule;
}

export interface UpdatePedidosYaPayload {
  ids: string[];
  config: PedidosYaConfig;
}
