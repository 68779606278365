export enum commons {
  SAVE = "commons.SAVE",
  LOADING = "commons.LOADING",
  ERROR_TITLE = "common.ERROR_TITLE",
  ERROR_SUBTITLE = "common.ERROR_SUBTITLE",
  REQUIRED_FIELD = "common.REQUIRED_FIELD",
  QUANTITY_ERROR = "common.QUANTITY_ERROR",
  ADD = "common.ADD",
  MODIFY = "common.MODIFY",
  VIEW_DETAIL = "common.VIEW_DETAIL",
  CANCEL = "common.CANCEL",
  CONTINUE = "common.CONTINUE",
  SEARCH = "common.SEARCH",
  COUNTRY = "common.COUNTRY",
  CITY = "common.CITY",
  LOCATION = "common.LOCATION",
  FILTERS = "common.FILTERS",
  PLATFORM = "common.PLATFORM",
  TABLE_EMPTY = "common.TABLE_EMPTY",
  STATUS = "common.STATUS",
  BRAND = "common.BRAND",
  ACTION = "common.ACTION",
  UPDATE = "common.UPDATE",
  SET_UP = "common.SET_UP",
  STORE = "common.STORE",
  SCHEDULE = "common.SCHEDULE",
  TIMETABLE = "common.TIMETABLE",
  TURN_ON = "common.TURN_ON",
  TURN_OFF = "common.TURN_OFF",
  ON = "common.ON",
  OFF = "common.OFF",
  CREATE = "common.CREATE",
  THEORETICAL = "common.THEORETICAL",
  REASON = "common.REASON",
  START = "common.START",
  END = "common.END",
  ID = "common.ID",
  NAME = "common.NAME",
  KITCHEN = "common.KITCHEN",
  ALL = "common.ALL",
  RETURN = "common.RETURN",
  ALL_CITIES = "common.ALL_CITIES",
  ALL_KITCHENS = "common.ALL_KITCHENS",
  DATE = "common.DATE",
  DELETE = "common.DELETE",
  ACCEPT = "common.ACCEPT",
  TIME_ZONE = "common.TIME_ZONE",
  COUNTRIES = "common.COUNTRIES",
  STATION_TYPE = "common.STATION_TYPE",
  TYPE = "common.TYPE",
  IMAGE = "common.IMAGE",
  UPLOAD_IMAGE = "common.UPLOAD_IMAGE",
  CLIC_TO_UPLOAD_IMAGE = "common.CLIC_TO_UPLOAD_IMAGE",
  ADDRESS = "common.ADDRESS",
  POLYGONS = "common.POLYGONS",
  ALERT = "common.ALERT",
  WARNING = "common.WARNING",
  EXPECTED = "common.EXPECTED",
  LONGITUDE = "common.LONGITUDE",
  LATITUDE = "common.LATITUDE",
  ACTIVE = "common.ACTIVE",
  INACTIVE = "common.INACTIVE",
  LOW = "common.LOW",
  MEDIUM = "common.MEDIUM",
  HIGH = "common.HIGH",
  COVERAGE = "common.COVERAGE",
  YES = "common.YES",
  NO = "common.NO",
  ENTER = "common.ENTER",
}

export enum storeManager {
  TITLE = "storeManager.TITLE",
  MARKETPLACE = "storeManager.MARKETPLACE",
  IS_PRIORITY = "storeManager.IS_PRIORITY",
  IS_PARENT = "storeManager.IS_PARENT",
  STORES = "storeManager.STORES",
  SCHEDULED = "storeManager.SCHEDULED",
}

export enum kitchenManager {
  TITLE = "kitchenManager.TITLE",
  LINK_STATION = "kitchenManager.LINK_STATION",
  LINK_STATION_SUCCESS = "kitchenManager.LINK_STATION_SUCCESS",
  SETUP_TITLE = "kitchenManager.SETUP_TITLE",
  PRODUCTION_CENTER = "kitchenManager.PRODUCTION_CENTER",
  KITCHEN = "kitchenManager.KITCHEN",
  BOTH = "kitchenManager.BOTH",
  RAPPIALLIESUSER = "kitchenManager.rappialliesuser",
  RAPPIALLIESPASSWORD = "kitchenManager.rappiAlliesPassword",
}

export enum brandManager {
  TITLE = "brandManager.TITLE",
}

export enum storeStatus {
  Upcoming = "storeStatus.Upcoming",
  Launched = "storeStatus.Launched",
  Paused = "storeStatus.Paused",
  Terminated = "storeStatus.Terminated",
  OPEN = "storeStatus.OPEN",
  TEMP_CLOSED = "storeStatus.TEMP_CLOSED",
  NOT_LAUNCHED = "storeStatus.NOT_LAUNCHED",
  PERM_CLOSED = "storeStatus.PERM_CLOSED",
  INTERNAL = "storeStatus.INTERNAL",
  REMOTE = "storeStatus.REMOTE",
}

export enum alertMessage {
  ROWS_SELECTED = "alertMessage.ROWS_SELECTED",
  SUCCESSFUL_PROCESS = "alertMessage.SUCCESSFUL_PROCESS",
  FAILED_PROCESS = "alertMessage.FAILED_PROCESS",
}

export enum reasonOnOff {
  OPERATING_CORRECTLY = "reasonOnOff.OPERATING_CORRECTLY",
  STAFF_SHORTAGE = "reasonOnOff.STAFF_SHORTAGE",
  KITCHEN_OPENING_ISSUES = "reasonOnOff.KITCHEN_OPENING_ISSUES",
  GAS_FAILURES = "reasonOnOff.GAS_FAILURES",
  INTERNET_FAILURES = "reasonOnOff.INTERNET_FAILURES",
  POWER_FAILURES = "reasonOnOff.POWER_FAILURES",
  WATER_FAILURES = "reasonOnOff.WATER_FAILURES",
  STOCKOUTS = "reasonOnOff.STOCKOUTS",
  SALES_OVERFLOW = "reasonOnOff.SALES_OVERFLOW",
  SPECIAL_EVENTS = "reasonOnOff.SPECIAL_EVENTS",
  SCHEDULED_MAINTENANCE = "reasonOnOff.SCHEDULED_MAINTENANCE",
  RELOCATION = "reasonOnOff.RELOCATION",
  STAFF_TRAINING = "reasonOnOff.STAFF_TRAINING",
  OUT_OF_SERVICE_BRAND = "reasonOnOff.OUT_OF_SERVICE_BRAND",
  INTEGRATION_LACK = "reasonOnOff.INTEGRATION_LACK",
  WITHOUT_REASON = "reasonOnOff.WITHOUT_REASON",
  BY_SYSTEM = "reasonOnOff.BY_SYSTEM",
  CLOSED_BY_PLATFORM = "reasonOnOff.CLOSED_BY_PLATFORM",
}

export enum daysOfWeek {
  MONDAY = "daysOfWeek.MONDAY",
  TUESDAY = "daysOfWeek.TUESDAY",
  WEDNESDAY = "daysOfWeek.WEDNESDAY",
  THURSDAY = "daysOfWeek.THURSDAY",
  FRIDAY = "daysOfWeek.FRIDAY",
  SATURDAY = "daysOfWeek.SATURDAY",
  SUNDAY = "daysOfWeek.SUNDAY",
}

export enum scheduled {
  CHAGE_STATUS_MESSAGE = "daysOfWeek.CHAGE_STATUS_MESSAGE",
  ARCHIVE = "scheduled.ARCHIVE",
}

export enum countries {
  COL = "COL",
  BRA = "BRA",
  PER = "PER",
  MEX = "MEX",
  ECU = "ECU",
}

export enum modulesLocale {
  SELECT_MODULE_TITLE = "modulesLocale.SELECT_MODULE_TITLE",
  SELECT_MODULE_SUBTITLE = "modulesLocale.SELECT_MODULE_SUBTITLE",
  ADMIN_TITLE = "modulesLocale.ADMIN_TITLE",
  ADMIN_SUBTITLE = "modulesLocale.ADMIN_SUBTITLE",
}

export enum StatusLocale {
  ARCHIVED = "StatusLocale.ARCHIVED",
  DONE = "StatusLocale.DONE",
  PENDING = "StatusLocale.PENDING",
}

export type Locales = {
  [key in
    | commons
    | storeManager
    | kitchenManager
    | storeStatus
    | alertMessage
    | reasonOnOff
    | daysOfWeek
    | scheduled
    | countries
    | brandManager
    | modulesLocale
    | StatusLocale]: string;
};
