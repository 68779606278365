import ModuleKitchens from "app/pages/Kitchens";
import ModuleStores from "app/pages/Stores";
import ModuleBrands from "app/pages/Brands";
import { TypeOfRole as Role, TypeOfRole } from "utils/role";
import ModuleSelectorPage from "app/pages/ModuleSelector";

export interface CustomRoute {
  path: string;
  element?: JSX.Element;
  childrens?: CustomRoute[];
  roles?: TypeOfRole[];
}

export const rolesWithAllAccess = [Role.TECH, Role.PRODUCTO, Role.TECH_OPS];

export const routeConfig: CustomRoute[] = [
  {
    path: "modules",
    element: <ModuleSelectorPage />,
    childrens: [
      {
        path: ":typeModule",
        element: <ModuleSelectorPage />,
      },
    ],
  },
  {
    path: "stores",
    element: <ModuleStores />,
  },
  {
    path: "kitchens",
    element: <ModuleKitchens />,
  },
  {
    path: "brands",
    element: <ModuleBrands />,
  },
];
