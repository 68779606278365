import i18n, { TFunction } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { esLocale } from "./es";
import { ptLocale } from "./pt";
import {
  enLocaleBaseTable,
  enLocaleLocationSelector,
  enLocaleTopBar,
  esLocaleBaseTable,
  esLocaleLocationSelector,
  esLocaleTopBar,
  ptLocaleBaseTable,
  ptLocaleLocationSelector,
  ptLocaleTopBar,
} from "@foodology-co/alejandria";
import { enLocale } from "./en";

const initI18n = async (): Promise<TFunction> =>
  i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .init({
      // the translations
      // (tip move them in a JSON file and import them,
      // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
      resources: {
        es: {
          translation: {
            ...esLocale,
            ...esLocaleBaseTable,
            ...esLocaleTopBar,
            ...esLocaleLocationSelector,
          },
        },
        pt: {
          translation: {
            ...ptLocale,
            ...ptLocaleBaseTable,
            ...ptLocaleTopBar,
            ...ptLocaleLocationSelector,
          },
        },
        en: {
          translation: {
            ...enLocale,
            ...enLocaleBaseTable,
            ...enLocaleTopBar,
            ...enLocaleLocationSelector,
          },
        },
      },
      fallbackLng: "es",

      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
    });

export default initI18n;
