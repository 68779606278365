import {
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { ManagerDialogFooter } from "app/components/common/Manager/Dialog/Footer";
import { useAlert } from "app/hooks/useAlert";
import { commons } from "app/i18n/types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BaseDialogProps,
  defaultData,
  FormState,
  handleValidations,
} from "./utils";
import { useModal } from "app/hooks/useModal";
import { Actions, handleModalIcon } from "utils/modal";
import { Brand, BrandSeed } from "core/brand/entities/Brand";
import { StationType } from "core/station/entities/Station";
import { upsertBrand } from "core/brand/repository/brandRepo";
import ImageUploader from "app/components/common/ImageUploader";
import { convertToBase64 } from "app/utils/file";
import { useAppSelector } from "app/hooks/useAppSelector";

interface Props extends BaseDialogProps {
  item?: Brand;
  stationTypes: StationType[];
}

export const HandleForm = (props: Props) => {
  const {
    item: initialValues,
    onClose,
    stationTypes,
    onSuccess,
    action,
  } = props;
  const { t } = useTranslation();
  const alert = useAlert();
  const modal = useModal();
  const session = useAppSelector((state) => state.session.data);

  const toFormState = (): FormState =>
    action === Actions.edit
      ? {
          ...initialValues,
        }
      : defaultData(session?.companyId);

  const [formState, setFormState] = useState<FormState>(() => toFormState());
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setFormState(() => toFormState());
  }, [initialValues]);

  useEffect(() => {
    modal.validator(handleValidations, {
      ...formState,
      stationTypeId: formState.stationType?._id,
    });
  }, [formState]);

  const getResponse = (data: FormState) => {
    return upsertBrand(data as BrandSeed);
  };

  const handleSubmit = useCallback(() => {
    if (
      !handleValidations.isValidSync({
        ...formState,
        stationTypeId: formState.stationType?._id,
      })
    ) {
      return;
    }
    setIsLoading(true);
    getResponse(formState)
      .then(() => {
        alert.success();
        onSuccess();
        onClose();
      })
      .catch(() => {
        alert.error();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [formState, props.action]);

  const onImageUpload = (file: File | null) => {
    if (!file) return;
    const { name, type } = file;
    convertToBase64(file).then((base64) => {
      setFormState((prev) => ({ ...prev, image: { name, type, base64 } }));
    });
  };

  return (
    <Grid container spacing={3}>
      {isLoading && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}

      <Grid item xs={7}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                type="text"
                variant="outlined"
                label={"Brand ID"}
                value={formState.brandId ?? ""}
                InputProps={{
                  endAdornment: handleModalIcon(modal.errors["brandId"]),
                }}
                onChange={(event) => {
                  const newValue = event.target.value;
                  setFormState((prev) => ({
                    ...prev,
                    brandId: newValue,
                  }));
                }}
                focused={!!formState.brandId}
                disabled={!(action === Actions.add)}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                type="text"
                variant="outlined"
                label={t(commons.NAME)}
                value={formState.name ?? ""}
                InputProps={{
                  endAdornment: handleModalIcon(modal.errors["name"]),
                }}
                onChange={(event) => {
                  const newValue = event.target.value;
                  setFormState((prev) => ({
                    ...prev,
                    name: newValue,
                  }));
                }}
                focused={!!formState.name}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>{t(commons.STATION_TYPE)}</InputLabel>
              <Select
                label={t(commons.STATION_TYPE)}
                value={formState.stationType?._id ?? ""}
                endAdornment={handleModalIcon(modal.errors["stationTypeId"])}
                onChange={(event: SelectChangeEvent<string>) => {
                  const newValue = event.target.value;
                  const stationType = stationTypes.find(
                    (el) => el.id === newValue
                  );
                  if (!stationType) return;
                  setFormState((prev) => ({
                    ...prev,
                    stationType: {
                      _id: stationType.id,
                      name: stationType.name,
                      slug: stationType.slug,
                    },
                  }));
                }}
              >
                {stationTypes.map((option) => (
                  <MenuItem key={`Brand-${option.id}`} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>{t(commons.STATUS)}</InputLabel>
              <Select
                label={t(commons.STATUS)}
                value={formState.status ?? ""}
                onChange={(event) => {
                  const newValue = event.target.value as Brand["status"];
                  setFormState((prev) => ({
                    ...prev,
                    status: newValue,
                  }));
                }}
                endAdornment={handleModalIcon(modal.errors["status"])}
                autoComplete="off"
              >
                <MenuItem value="ACTIVE">{t(commons.ACTIVE)}</MenuItem>
                <MenuItem value="INACTIVE">{t(commons.INACTIVE)}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={5}>
        <ImageUploader
          image={initialValues?.logoUrl}
          onImageUpload={onImageUpload}
        />
      </Grid>

      <Grid item xs={12}>
        <ManagerDialogFooter
          onCancel={props.onClose}
          mainButton={{
            children: t(commons.SAVE),
            onClick: handleSubmit,
            disabled: isLoading || !!Object.keys(modal.errors).length,
          }}
        />
      </Grid>
    </Grid>
  );
};
