import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSRowData,
  KOSSelectedResult,
} from "@foodology-co/alejandria";
import Box from "@mui/material/Box";
import { commons, daysOfWeek, storeStatus } from "app/i18n/types";
import { Store } from "core/stores/entities/Store";
import { FunctionComponent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { IconButton } from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { Actions, StoreModal } from "utils/modal";

interface Props {
  filteredStores: Store[];
  loading: boolean;
  selectedItems: KOSSelectedResult[];
  setSelectedItems: (selectedItems: KOSSelectedResult[]) => void;
  setModal: (modal: StoreModal) => void;
}

const StoreTable: FunctionComponent<Props> = (props) => {
  const { filteredStores, loading, selectedItems, setSelectedItems, setModal } =
    props;
  const { t } = useTranslation();

  const getActions = (row: KOSRowData) => {
    return (
      <>
        <IconButton
          color="warning"
          onClick={() =>
            setModal({
              open: true,
              item: {
                id: row["id"],
                platform: row["platform"],
              },
              action: Actions.edit,
              onClose: () => {},
            })
          }
        >
          <ModeEditOutlineOutlinedIcon />
        </IconButton>
        <IconButton
          color="primary"
          onClick={() =>
            setModal({
              item: {
                id: row["id"],
                platform: row["platform"],
                kitchenId: row["kitchenId"],
                brandId: row["brandId"],
                country: row["country"],
              },
              action: Actions.config,
              onClose: () => {},
            })
          }
        >
          <SettingsOutlinedIcon />
        </IconButton>
      </>
    );
  };

  const getAvailability = (item: Store): string => {
    const { availability } = item;
    let status = availability.operation as string;
    if (status === "Launched" && availability.paused) {
      status = "Paused";
    }
    return t(`storeStatus.${status}`);
  };

  const getHeader = useCallback((): KOSBaseTableHeader[] => {
    return [
      {
        label: "Universal Id",
        field: "externalId",
      },
      {
        label: t(commons.LOCATION),
        field: "kitchenId",
        sort: true,
      },
      {
        label: t(commons.BRAND),
        field: "brandId",
        sort: true,
      },
      {
        label: t(commons.COUNTRY),
        field: "country",
        sort: true,
      },
      {
        label: t(commons.CITY),
        field: "city",
        sort: true,
      },
      {
        label: t(commons.PLATFORM),
        field: "platform",
        sort: true,
      },
      {
        label: `${t(storeStatus.INTERNAL)} (${t(commons.STATUS)})`,
        text: (item) => getAvailability(item as Store),
        align: "center",
        sort: true,
      },
      {
        label: `${t(storeStatus.REMOTE)} (${t(commons.STATUS)})`,
        text: (item) => t(item.online.remote ? commons.ON : commons.OFF),
        align: "center",
        sort: true,
      },
      {
        label: `${t(commons.REASON)} (${t(commons.STATUS)})`,
        text: (item) =>
          item.data?.statusReason
            ? t(`reasonOnOff.${item.data?.statusReason}`)
            : undefined,
        align: "center",
        sort: true,
      },
      {
        label: "Marketplace",
        text: (item) =>
          t(item.data?.legacy_config?.isMarketPlace ? commons.YES : commons.NO),
        align: "center",
        sort: true,
      },
      {
        label: t(daysOfWeek.MONDAY),
        text: (item) => item.data?.legacy_schedule?.week?.monday,
        sort: true,
      },
      {
        label: t(daysOfWeek.TUESDAY),
        text: (item) => item.data?.legacy_schedule?.week?.tuesday,
        sort: true,
      },
      {
        label: t(daysOfWeek.WEDNESDAY),
        text: (item) => item.data?.legacy_schedule?.week?.wednesday,
        sort: true,
      },
      {
        label: t(daysOfWeek.THURSDAY),
        text: (item) => item.data?.legacy_schedule?.week?.thursday,
        sort: true,
      },
      {
        label: t(daysOfWeek.FRIDAY),
        text: (item) => item.data?.legacy_schedule?.week?.friday,
        sort: true,
      },
      {
        label: t(daysOfWeek.SATURDAY),
        text: (item) => item.data?.legacy_schedule?.week?.saturday,
        sort: true,
      },
      {
        label: t(daysOfWeek.SUNDAY),
        text: (item) => item.data?.legacy_schedule?.week?.sunday,
        sort: true,
      },
      {
        label: t(commons.ACTION),
        align: "center",
        component: (row) => getActions(row),
      },
    ];
  }, []);

  return (
    <Box mt={2}>
      <KOSBaseTable
        columns={getHeader()}
        pagination={{ enable: true }}
        rows={{
          data: filteredStores,
          total: filteredStores.length ?? 0,
          loading: !!loading,
        }}
        selectable={{
          canSelectAll: true,
          resultHeader: ["id", "platform", "kitchenId", "country", "brandId"],
          items: selectedItems,
          onChange: setSelectedItems,
        }}
      />
    </Box>
  );
};

export default StoreTable;
