/* eslint-disable no-useless-computed-key */
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { ManagerDialogFooter } from "app/components/common/Manager/Dialog/Footer";
import { useAlert } from "app/hooks/useAlert";
import { commons, storeManager } from "app/i18n/types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BaseDialogProps,
  defaultData,
  FormState,
  handleValidations,
} from "./utils";
import { Store } from "core/stores/entities/Store";
import { useModal } from "app/hooks/useModal";
import { storeCreate, storeUpdate } from "core/stores/repository/storeRepo";
import { Actions, handleModalIcon } from "utils/modal";
import KitchenSelector from "app/components/Kitchen/Selector";
import { IOSSwitch } from "utils/generalUI";
import { useAllBrands } from "app/hooks/useAllBrands";
import { useAllCities } from "app/hooks/useAllCities";

interface Props extends BaseDialogProps {
  item?: Store;
  platforms: string[];
}

export const HandleForm = (props: Props) => {
  const {
    item: initialValues,
    onClose,
    platforms,
    onSuccess,
    action,
    country,
    city,
    kitchenId,
  } = props;
  const { t } = useTranslation();
  const alert = useAlert();
  const modal = useModal();
  const useBrands = useAllBrands();
  const useCity = useAllCities();

  const toFormState = (): FormState =>
    action === Actions.edit
      ? {
          ...initialValues,
        }
      : {
          country,
          city,
          kitchenId,
          availability: { operation: "Upcoming" },
          data: defaultData,
          tags: {},
        };

  const [formState, setFormState] = useState<FormState>(() => toFormState());
  const brands = useBrands.list
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name));
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setFormState(() => toFormState());
  }, [initialValues]);

  useEffect(() => {
    modal.validator(handleValidations, {
      ...formState,
      warehouseId: formState.data?.warehouse_id,
    });
  }, [formState]);

  const getResponse = (data: FormState) => {
    if (action === Actions.edit) {
      return storeUpdate(data);
    }
    return storeCreate(data);
  };

  const handleSubmit = useCallback(() => {
    if (
      !handleValidations.isValidSync({
        ...formState,
        warehouseId: formState.data?.warehouse_id,
      })
    ) {
      return;
    }

    let toSave = { ...formState };
    if (!toSave.data?.legacy_schedule?.timeZone && action === Actions.add) {
      toSave = {
        ...toSave,
        data: {
          ...toSave.data,
          ["legacy_schedule"]: {
            ...toSave.data["legacy_schedule"],
            timeZone: useCity.byCode(toSave.city ?? "")?.timeZone,
          },
        },
      };
    }
    setIsLoading(true);
    getResponse(toSave)
      .then(() => {
        alert.success();
        onSuccess();
        onClose();
      })
      .catch(() => {
        alert.error();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [formState, props.action]);

  return (
    <Grid container spacing={3}>
      {isLoading && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}

      <Grid item xs={6}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            type="text"
            variant="outlined"
            label={"Store ID"}
            value={formState.externalId ?? ""}
            InputProps={{
              endAdornment: handleModalIcon(modal.errors["externalId"]),
            }}
            onChange={(event) => {
              const newValue = event.target.value;
              setFormState((prev) => ({
                ...prev,
                externalId: newValue,
              }));
            }}
            disabled={!(action === Actions.add)}
            focused={!!formState.externalId}
          />
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            type="text"
            variant="outlined"
            label={"External ID"}
            value={formState.data?.warehouse_id ?? ""}
            InputProps={{
              endAdornment: handleModalIcon(modal.errors["warehouseId"]),
            }}
            onChange={(event) => {
              const newValue = event.target.value;
              setFormState((prev) => ({
                ...prev,
                data: {
                  ...prev.data,
                  warehouse_id: newValue,
                },
              }));
            }}
            focused={!!formState.data?.warehouse_id}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <KitchenSelector
          selected={{
            country: formState.country,
            city: formState.city,
            kitchen: formState.kitchenId,
          }}
          onChange={{
            country: (country) =>
              setFormState((prev) => ({
                ...prev,
                country: country?.code,
              })),
            city: (city) => {
              if (action === Actions.add) {
                setFormState((prev) => ({
                  ...prev,
                  city: city?.code,
                  data: {
                    ...prev.data,
                    ["legacy_schedule"]: {
                      ...prev.data["legacy_schedule"],
                      timeZone: city?.timeZone,
                    },
                  },
                }));
              }
              if (action === Actions.edit) {
                setFormState((prev) => ({
                  ...prev,
                  city: city?.code,
                }));
              }
            },
            kitchen: (kitchen) =>
              setFormState((prev) => ({
                ...prev,
                kitchenId: kitchen?.kitchenId,
              })),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel>{t(commons.PLATFORM)}</InputLabel>
          <Select
            label={t(commons.PLATFORM)}
            value={formState.platform ?? ""}
            endAdornment={handleModalIcon(modal.errors["platform"])}
            onChange={(event: SelectChangeEvent<string>) => {
              const newValue = event.target.value;
              setFormState((prev) => ({
                ...prev,
                platform: newValue,
                ownerId: newValue,
              }));
            }}
          >
            {platforms.map((option) => (
              <MenuItem key={`Platform-${option}`} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel>{t(commons.BRAND)}</InputLabel>
          <Select
            label={t(commons.BRAND)}
            value={formState.brandId ?? ""}
            endAdornment={handleModalIcon(modal.errors["brandId"])}
            onChange={(event: SelectChangeEvent<string>) => {
              const newValue = event.target.value;
              setFormState((prev) => ({
                ...prev,
                brandId: newValue,
              }));
            }}
          >
            {brands.map((option) => (
              <MenuItem key={`Brand-${option.id}`} value={option.brandId}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={4}>
        <FormControlLabel
          control={
            <IOSSwitch
              sx={{ m: 1 }}
              onChange={(_, checked) => {
                setFormState((prev) => ({
                  ...prev,
                  data: {
                    ...prev.data,
                    legacy_config: {
                      ...prev.data.legacy_config,
                      isMarketPlace: checked,
                    },
                  },
                }));
              }}
              checked={formState.data?.legacy_config?.isMarketPlace ?? false}
            />
          }
          label={t(storeManager.MARKETPLACE)}
        />
      </Grid>
      <Grid item xs={4}>
        <FormControlLabel
          control={
            <IOSSwitch
              sx={{ m: 1 }}
              onChange={(_, checked) => {
                setFormState((prev) => ({
                  ...prev,
                  data: {
                    ...prev.data,
                    isPriority: checked,
                  },
                }));
              }}
              checked={formState.data?.isPriority ?? false}
            />
          }
          label={t(storeManager.IS_PRIORITY)}
        />
      </Grid>
      <Grid item xs={4}>
        <FormControlLabel
          control={
            <IOSSwitch
              sx={{ m: 1 }}
              onChange={(_, checked) => {
                setFormState((prev) => ({
                  ...prev,
                  data: {
                    ...prev.data,
                    legacy_config: {
                      ...prev.data.legacy_config,
                      isParentStore: checked,
                    },
                  },
                }));
              }}
              checked={formState.data?.legacy_config?.isParentStore ?? false}
            />
          }
          label={t(storeManager.IS_PARENT)}
        />
      </Grid>

      <Grid item xs={12}>
        <ManagerDialogFooter
          onCancel={props.onClose}
          mainButton={{
            children: t(commons.SAVE),
            onClick: handleSubmit,
            disabled: isLoading || !!Object.keys(modal.errors).length,
          }}
        />
      </Grid>
    </Grid>
  );
};
