import PageHeader from "app/components/PageHeader";
import { brandManager, commons } from "app/i18n/types";
import { FunctionComponent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMount } from "react-use";
import BrandFilters from "./Filters";
import { getAllBrands } from "core/brand/repository/brandRepo";
import { Brand } from "core/brand/entities/Brand";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Actions, BrandModal } from "utils/modal";
import BrandTable from "app/components/Brand/Table";
import BrandManagerDialog from "app/components/Brand/Maganer";
import { KOSPageBox } from "@foodology-co/alejandria";

interface Props {}

const ModuleBrands: FunctionComponent<Props> = () => {
  const { t } = useTranslation();

  const [brands, setBrands] = useState<Brand[]>([]);
  const [filteredBrands, setFilteredBrands] = useState<Brand[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [modal, setModal] = useState<BrandModal>();

  const getData = useCallback(() => {
    setLoading(true);
    getAllBrands()
      .then((response) => {
        setBrands(response);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useMount(() => {
    getData();
  });

  const onCloseModal = () => setModal(undefined);

  return (
    <KOSPageBox>
      <PageHeader
        title={brandManager.TITLE}
        rightArea={[
          {
            children: t(commons.CREATE),
            variant: "contained",
            startIcon: <AddOutlinedIcon />,
            onClick: () =>
              setModal({
                open: true,
                action: Actions.add,
                onClose: onCloseModal,
              }),
          },
          {
            children: t(commons.UPDATE),
            variant: "outlined",
            startIcon: <RestartAltOutlinedIcon />,
            onClick: getData,
          },
        ]}
      />
      <BrandFilters
        brands={brands}
        setFilteredBrands={setFilteredBrands}
        loading={loading}
      />
      <BrandTable
        loading={loading}
        filteredBrands={filteredBrands}
        setModal={setModal}
      />
      {modal?.open && (
        <BrandManagerDialog
          {...modal}
          onClose={onCloseModal}
          onSuccess={getData}
        />
      )}
    </KOSPageBox>
  );
};

export default ModuleBrands;
