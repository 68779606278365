import { brandManager, kitchenManager, modulesLocale, storeManager } from "app/i18n/types";
import { Module } from "utils/module";

export enum LocationType {
  CP = "PCENTER",
  KITCHEN = "KITCHEN",
}

export const adminModule: Array<Module> = [
  {
    moduleId: "storeModule",
    moduleName: storeManager.TITLE,
    link: "/stores",
  },
  {
    moduleId: "kitchenModule",
    moduleName: kitchenManager.TITLE,
    link: "/kitchens",
  },
  {
    moduleId: "brandModule",
    moduleName: brandManager.TITLE,
    link: "/brands",
  },
];

export const modules: Array<Module> = [
  {
    moduleId: "adminModule",
    moduleName: modulesLocale.ADMIN_TITLE,
    description: modulesLocale.ADMIN_SUBTITLE,
    submodules: adminModule,
  },
];
