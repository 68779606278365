/* eslint-disable no-template-curly-in-string */
import {
  Locales,
  alertMessage,
  commons,
  daysOfWeek,
  kitchenManager,
  reasonOnOff,
  storeManager,
  storeStatus,
  scheduled,
  countries,
  brandManager,
  modulesLocale,
  StatusLocale,
} from "./types";

export const enLocale: Locales = {
  [commons.SAVE]: "Save",
  [commons.LOADING]: "Loading",
  [commons.ERROR_TITLE]: "The page does not exist or you do not have access...",
  [commons.ERROR_SUBTITLE]: "Please contact the administrator.",
  [commons.REQUIRED_FIELD]: "Required value",
  [commons.QUANTITY_ERROR]: "Quantity must be greater than 0",
  [commons.ADD]: "Add",
  [commons.MODIFY]: "Modify",
  [commons.VIEW_DETAIL]: "View detail",
  [commons.CANCEL]: "Cancel",
  [commons.CONTINUE]: "Continue",
  [commons.SEARCH]: "Search",
  [commons.COUNTRY]: "Country",
  [commons.CITY]: "City",
  [commons.LOCATION]: "Location",
  [commons.FILTERS]: "Filters",
  [commons.PLATFORM]: "Platform",
  [commons.TABLE_EMPTY]: "No records to display",
  [commons.STATUS]: "Status",
  [commons.BRAND]: "Brand",
  [commons.ACTION]: "Action",
  [commons.UPDATE]: "Update",
  [commons.SET_UP]: "Set up",
  [commons.STORE]: "Store",
  [commons.SCHEDULE]: "Schedule",
  [commons.TIMETABLE]: "Timetable",
  [commons.TURN_ON]: "Turn on",
  [commons.TURN_OFF]: "Turn off",
  [commons.ON]: "On",
  [commons.OFF]: "Off",
  [commons.CREATE]: "Create",
  [commons.THEORETICAL]: "Theoretical",
  [commons.REASON]: "Reason",
  [commons.START]: "Start",
  [commons.END]: "End",
  [commons.ID]: "ID",
  [commons.NAME]: "Name",
  [commons.KITCHEN]: "Kitchen",
  [commons.ALL]: "All",
  [commons.RETURN]: "Return",
  [commons.ALL_CITIES]: "All cities",
  [commons.ALL_KITCHENS]: "All kitchens",
  [commons.DATE]: "Date",
  [commons.DELETE]: "Delete",
  [commons.ACCEPT]: "Accept",
  [commons.TIME_ZONE]: "Time zone",
  [commons.COUNTRIES]: "Countries",
  [commons.STATION_TYPE]: "Station type",
  [commons.TYPE]: "Type",
  [commons.IMAGE]: "Image",
  [commons.UPLOAD_IMAGE]: "Upload image",
  [commons.CLIC_TO_UPLOAD_IMAGE]: "Click to upload image",
  [commons.ADDRESS]: "Address",
  [commons.POLYGONS]: "Polygons",
  [commons.ALERT]: "Alert",
  [commons.WARNING]: "Warning",
  [commons.EXPECTED]: "Expected",
  [commons.LONGITUDE]: "Longitude",
  [commons.LATITUDE]: "Latitude",
  [commons.ACTIVE]: "Active",
  [commons.INACTIVE]: "Inactive",
  [commons.LOW]: "Low",
  [commons.MEDIUM]: "Medium",
  [commons.HIGH]: "High",
  [commons.COVERAGE]: "Coverage",
  [commons.YES]: "Yes",
  [commons.NO]: "No",
  [commons.ENTER]: "Enter",

  [storeManager.TITLE]: "Store manager",
  [storeManager.MARKETPLACE]: "Marketplace",
  [storeManager.IS_PRIORITY]: "Turbo",
  [storeManager.IS_PARENT]: "Parent store",
  [storeManager.STORES]: "Stores",
  [storeManager.SCHEDULED]: "Scheduled",

  [storeStatus.Upcoming]: "Upcoming",
  [storeStatus.Launched]: "Open",
  [storeStatus.Paused]: "Temporarily closed",
  [storeStatus.Terminated]: "Permanently closed",
  [storeStatus.OPEN]: "Open",
  [storeStatus.NOT_LAUNCHED]: "Not launched",
  [storeStatus.PERM_CLOSED]: "Permanently closed",
  [storeStatus.TEMP_CLOSED]: "Temporarily closed",
  [storeStatus.INTERNAL]: "Internal",
  [storeStatus.REMOTE]: "Remote",

  [brandManager.TITLE]: "Brand manager",

  [kitchenManager.TITLE]: "Kitchen manager",
  [kitchenManager.LINK_STATION]: "Link stations",
  [kitchenManager.LINK_STATION_SUCCESS]: "Stations linked successfully",
  [kitchenManager.SETUP_TITLE]: "Set up kitchens",
  [kitchenManager.PRODUCTION_CENTER]: "Production center",
  [kitchenManager.KITCHEN]: "Kitchen",
  [kitchenManager.BOTH]: "Both",
  [kitchenManager.RAPPIALLIESUSER]: "Rappi Allies User",
  [kitchenManager.RAPPIALLIESPASSWORD]: "Rappi Allies Password",

  [alertMessage.ROWS_SELECTED]: "You have selected ({{rows}}) records.",
  [alertMessage.SUCCESSFUL_PROCESS]: "Process completed successfully.",
  [alertMessage.FAILED_PROCESS]: "An error occurred in the process.",

  [reasonOnOff.OPERATING_CORRECTLY]: "Operating correctly",
  [reasonOnOff.STAFF_SHORTAGE]: "Staff shortage",
  [reasonOnOff.KITCHEN_OPENING_ISSUES]: "Kitchen opening issues",
  [reasonOnOff.GAS_FAILURES]: "Gas failures",
  [reasonOnOff.INTERNET_FAILURES]: "Internet failures",
  [reasonOnOff.POWER_FAILURES]: "Power failures",
  [reasonOnOff.WATER_FAILURES]: "Water failures",
  [reasonOnOff.STOCKOUTS]: "Stockouts",
  [reasonOnOff.SALES_OVERFLOW]: "Sales overflow",
  [reasonOnOff.SPECIAL_EVENTS]: "Special events",
  [reasonOnOff.SCHEDULED_MAINTENANCE]: "Scheduled maintenance",
  [reasonOnOff.RELOCATION]: "Relocation",
  [reasonOnOff.STAFF_TRAINING]: "Staff training",
  [reasonOnOff.OUT_OF_SERVICE_BRAND]: "Out-of-service brand",
  [reasonOnOff.INTEGRATION_LACK]: "Lack of integration",
  [reasonOnOff.WITHOUT_REASON]: "Without reason",
  [reasonOnOff.BY_SYSTEM]: "By system",
  [reasonOnOff.CLOSED_BY_PLATFORM]: "Closed by platform",

  [daysOfWeek.MONDAY]: "Monday",
  [daysOfWeek.TUESDAY]: "Tuesday",
  [daysOfWeek.WEDNESDAY]: "Wednesday",
  [daysOfWeek.THURSDAY]: "Thursday",
  [daysOfWeek.FRIDAY]: "Friday",
  [daysOfWeek.SATURDAY]: "Saturday",
  [daysOfWeek.SUNDAY]: "Sunday",

  [scheduled.CHAGE_STATUS_MESSAGE]: "Do you want to archive this schedule?",
  [scheduled.ARCHIVE]: "Archive",

  [countries.COL]: "Colombia",
  [countries.PER]: "Peru",
  [countries.BRA]: "Brazil",
  [countries.MEX]: "Mexico",
  [countries.ECU]: "Ecuador",

  [modulesLocale.SELECT_MODULE_TITLE]: "Select module",
  [modulesLocale.SELECT_MODULE_SUBTITLE]: "Select tool",
  [modulesLocale.ADMIN_TITLE]: "Admin",
  [modulesLocale.ADMIN_SUBTITLE]:
    "Select the tool you want to use to manage your stores.",

  [StatusLocale.PENDING]: "Pending",
  [StatusLocale.DONE]: "Done",
  [StatusLocale.ARCHIVED]: "Archived",
};
